import { FC, useState } from 'react';

import { Button } from 'antd';

import ContactSalesModal from '../ContactSalesModal/ContactSalesModal';

import styles from './ContactSalesModalWithButton.module.scss';

const ContactSalesModalWithButton: FC = (): JSX.Element => {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<Button className={styles.contactSales} onClick={(): void => setShowModal(true)}>
				Contact Sales
			</Button>
			<ContactSalesModal showModal={showModal} setShowModal={setShowModal} />
		</>
	);
};

export default ContactSalesModalWithButton;
