import { Dispatch, FC, ReactNode, SetStateAction, useId, useMemo, useState } from 'react';

import { Button, Flex, Typography } from 'antd';
import { ReactComponent as MailIcon } from 'assets/svg/mail.svg';
import { useIsMutating } from 'react-query';
import { corvusRedirectLink } from 'shared/constants/constants';
import CustomModal from 'shared/ui/CustomModal';

import ContactSalesModalForm from './ui/ContactSalesModalForm';

const { Text } = Typography;

type TContactSalesModalProps = {
	showModal: boolean;
	setShowModal: Dispatch<SetStateAction<boolean>>;
};

const ContactSalesModal: FC<TContactSalesModalProps> = ({
	showModal,
	setShowModal,
}): JSX.Element => {
	const formId = useId();
	const [step, setStep] = useState<number>(0);

	const onClose = (): void => {
		setShowModal(false);
	};

	const isMutating = !!useIsMutating({ mutationKey: ['contact-sales'] });

	const renderButtons = useMemo((): ReactNode[] => {
		const buttons = [];

		if (step === 0) {
			buttons.push(
				<Button disabled={isMutating} key="cancel" onClick={onClose} style={{ width: '100%' }}>
					Cancel
				</Button>,
				<Button
					loading={isMutating}
					key="submit"
					type="primary"
					form={formId}
					htmlType="submit"
					style={{ width: '100%' }}
				>
					Submit
				</Button>,
			);
		}

		if (step === 1) {
			buttons.push(
				<a key="ok" style={{ width: '100%' }} href={corvusRedirectLink}>
					<Button type="primary" style={{ width: '100%' }}>
						Ok
					</Button>
				</a>,
			);
		}

		return buttons;
	}, [step, isMutating]);

	return (
		<CustomModal
			width={step === 0 ? 610 : 448}
			open={showModal}
			onCancel={onClose}
			closable={step === 0}
			maskClosable={step === 0}
			title={step === 0 ? 'Contact Sales' : 'Thank you for contacting us!'}
			footer={renderButtons}
			centered
			destroyOnClose
		>
			{step === 0 && <ContactSalesModalForm setStep={setStep} formId={formId} />}
			{step === 1 && (
				<Flex justify="center" align="center" vertical gap={24}>
					<Text style={{ textAlign: 'center' }}>
						Your request was successfully send. We will reach out to you within one business day.
					</Text>
					<MailIcon />
				</Flex>
			)}
		</CustomModal>
	);
};

export default ContactSalesModal;
