import { Appearance } from '@stripe/stripe-js';

export const stripeStyles: Appearance = {
	theme: 'flat',
	rules: {
		'.Input': {
			borderRadius: '6px',
			border: '1px solid #D9D9D9',
			backgroundColor: 'rgb(232, 240, 254)',
			padding: '8px 12px',
			color: '#1A2026',
			fontFamily: `'Jost', sans-serif`,
			fontSize: '14px',
			fontWeight: '400',
			lineHeight: '22px',
		},
		'.Input--empty': {
			backgroundColor: '#FFF',
		},
		'.Input:focus': {
			outline: 'none',
			border: '1px solid #00AC8E',
			boxShadow: '0 0 0 2px rgba(0, 172, 142, 0.2)',
		},
		'.Input:hover': {
			border: '1px solid #00AC8E',
		},
		'.Input--invalid': {
			color: '#D03900',
			outline: 'none',
			borderColor: 'rgba(208, 57, 0, 0.6)',
			boxShadow: '0 0 0 2px rgba(208, 57, 0, 0.2)',
		},
		'.Input::placeholder': {
			fontSize: '14px',
			fontWeight: '400',
			lineHeight: '22px',
			color: '#8C8C8C',
		},
		'.Label': {
			color: '#1A2026',
			fontFamily: `'Jost', sans-serif`,
			fontSize: '16px',
			fontWeight: '600',
			lineHeight: '22px',
			marginBottom: '8px',
		},
		'.Error': {
			color: '#D03900',
		},
	},
};
