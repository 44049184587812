import { Dispatch, FC, SetStateAction, useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Button, Flex, Input, message } from 'antd';
import { ReactComponent as CouponCheckIcon } from 'assets/svg/couponCheck.svg';
import classNames from 'classnames';
import { useMutation } from 'react-query';
import {
	TSubscriptionResponse,
	applySubscriptionCoupon,
	deleteCoupon,
} from 'shared/api/subscription.service';
import { tiersTransform } from 'shared/constants/constants';
import { transformString } from 'shared/lib/lib';

import styles from './Summary.module.scss';

type TStripeFormProps = {
	subscriptionResponse: TSubscriptionResponse;
	setSubscriptionResponse: Dispatch<SetStateAction<TSubscriptionResponse>>;
};

const Summary: FC<TStripeFormProps> = ({
	subscriptionResponse,
	setSubscriptionResponse,
}): JSX.Element => {
	const {
		tierProductType,
		amount,
		tierProductPrice,
		reportsAddonPrices,
		tierTokenProductPrice,
		couponId,
		amountDiscount,
		percentageDiscount,
	} = subscriptionResponse;

	const [applyCoupon, setApplyCoupon] = useState(false);
	const [couponValue, setCouponValue] = useState('');

	const { mutateAsync: applySubscriptionCouponAsync } = useMutation({
		mutationFn: (couponId: string) => applySubscriptionCoupon(couponId),
	});

	const { mutateAsync: deleteCouponAsync } = useMutation({
		mutationFn: () => deleteCoupon(),
	});

	const handleDeleteCoupon = async (): Promise<void> => {
		try {
			setApplyCoupon(false);
			setCouponValue('');

			const response = await deleteCouponAsync();
			setSubscriptionResponse(response);
		} catch (e) {
			message.error(e?.response?.data?.message || e.message || 'Error!');
		}
	};

	const handleCouponApply = async (): Promise<void> => {
		if (!couponValue) return;
		try {
			const response = await applySubscriptionCouponAsync(couponValue);
			setSubscriptionResponse(response);
		} catch (e) {
			message.error(e?.response?.data?.message || e.message || 'Error!');
		}
	};

	return (
		<Flex vertical className={styles.container} gap={16}>
			<Flex align="center" justify="space-between" className={styles.header}>
				<p className={styles.tier}>{tiersTransform[tierProductType]}</p>
				<p className={styles.tierPrice}>
					${tierProductPrice / 100}
					<span className={styles.tierPriceSub}>/month</span>
				</p>
			</Flex>
			<Flex vertical gap={12} className={styles.sectionContainer}>
				<Flex justify="space-between" align="center">
					<p className={styles.text}>Link Token</p>
					<p className={styles.textSmall}>
						per token / billed monthly{'   '}
						<span className={styles.text}>${tierTokenProductPrice / 100}</span>
					</p>
				</Flex>
				{reportsAddonPrices &&
					Object.entries(reportsAddonPrices).map(([key, price]) => (
						<Flex key={key} justify="space-between" align="center">
							<p className={styles.text}>{transformString(key)}</p>
							<p className={styles.textSmall}>
								per month / billed monthly{'   '}
								<span className={styles.text}>${price / 100}</span>
							</p>
						</Flex>
					))}
			</Flex>
			<Flex vertical align="flex-start" gap={12} className={styles.sectionContainer}>
				<Flex style={{ width: '100%' }} justify="space-between" align="center">
					<p className={styles.text}>Subtotal</p>
					<p className={styles.text}>${amount / 100}</p>
				</Flex>
				{!couponId && !applyCoupon && (
					<Button
						type="link"
						className={styles.addPromotionCode}
						onClick={(): void => setApplyCoupon(true)}
					>
						Add promotion code
					</Button>
				)}
				{!couponId && applyCoupon && (
					<Input
						value={couponValue}
						onChange={(e): void => setCouponValue(e.target.value)}
						style={{ width: '50%' }}
						size="small"
						placeholder="Add promotion code"
						suffix={
							<Button
								onClick={handleCouponApply}
								size="small"
								type="link"
								className={styles.applyButton}
							>
								Apply
							</Button>
						}
					/>
				)}
				{couponId && (
					<Flex className={styles.appliedCoupon} align="center" justify="space-between" gap={8}>
						<Flex className={styles.left} align="center">
							<CouponCheckIcon />
						</Flex>
						<Flex className={styles.center} align="center" justify="flex-start">
							<p className={styles.couponIdText}>
								{couponId} <span className={styles.text}>applied</span>
							</p>
						</Flex>
						<Flex className={styles.right} align="center">
							<CloseOutlined className={styles.couponRemove} onClick={handleDeleteCoupon} />
						</Flex>
					</Flex>
				)}
				<Flex style={{ width: '100%' }} justify="space-between" align="center">
					<p className={styles.text}>Discount</p>
					<p className={styles.text}>
						{amountDiscount ? `-${amountDiscount / 100}$` : '0$'} ({percentageDiscount || 0}%)
					</p>
				</Flex>
			</Flex>
			<Flex vertical align="flex-start" gap={12}>
				<Flex style={{ width: '100%' }} justify="space-between" align="center">
					<p className={styles.text}>Total after trial</p>
					<p className={styles.text}>${amount / 100}</p>
				</Flex>

				<Flex style={{ width: '100%' }} justify="space-between" align="center">
					<p className={classNames(styles.text, styles.textBold)}>Total due today</p>
					<p className={classNames(styles.text, styles.textBold)}>${amount / 100}</p>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default Summary;
