import axios, { API_PREFIX } from 'shared/configs/axios/axios';
import { sessionIdLSKey } from 'shared/constants/constants';

const CONTROLLER_PREFIX = 'subscriptions';

export enum ADD_ONS_VALUES {
	INDUSTRY_REPORTS = 'INDUSTRY_REPORTS',
	PROCESS_REPORTS = 'PROCESS_REPORTS',
}

export enum SUBSCRIPTIONS_PLANS {
	PLAN = 'PLAN',
	BUILD = 'BUILD',
	GROW = 'GROW',
	ENTERPRISE = 'ENTERPRISE',
}

export type TSubscriptionsPlan = keyof typeof SUBSCRIPTIONS_PLANS;

export type TSubscriptionsPlans = TSubscriptionsPlan[];

export type TSubscriptionAddon = keyof typeof ADD_ONS_VALUES;

export type TSubscriptionAddons = TSubscriptionAddon[];

export type TCreateSubscriptionPayload = {
	subscriptionPlanTypes: TSubscriptionsPlans;
	subscriptionAddonTypes: TSubscriptionAddons;
};

export enum TEAR_PRODUCT_TYPE {
	TIER_1 = 'TIER_1',
	TIER_2 = 'TIER_2',
	TIER_3 = 'TIER_4',
}

export enum TEAR_TOKEN_PRODUCT_TYPE {
	TIER_1_TOKEN = 'TIER_1_TOKEN',
	TIER_2_TOKEN = 'TIER_2_TOKEN',
	TIER_3_TOKEN = 'TIER_3_TOKEN',
}

export type TSubscriptionResponse = {
	setupIntentId: string;
	paymentToken: string;
	tierProductType: keyof typeof TEAR_PRODUCT_TYPE;
	tierProductPrice: number;
	tierTokenProductType: keyof typeof TEAR_TOKEN_PRODUCT_TYPE;
	tierTokenProductPrice: number;
	reportsAddonPrices: {
		INDUSTRY_REPORTS_ADDON: number;
		PROCESS_REPORTS_ADDON: number;
	};
	numberOfLicenses: number;
	amount: number;
	couponId: string | null;
	amountDiscount: number | null;
	percentageDiscount: number | null;
};

export type TContactSalesPayload = {
	firstName: string;
	lastName: string;
	email: string;
	contactPhone: string;
	companyName: string;
};

export type TAssignCustomerPayload = {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
};

export type TAddSubscriptionDetailsResponse = {
	id: string;
	amount: number;
	paymentUrl: string;
};

export type TSubscriptionPricesInner = {
	INDUSTRY_REPORTS_ADDON: number;
	PROCESS_REPORTS_ADDON: number;
	TIER_1: number;
	TIER_1_TOKEN: number;
	TIER_2: number;
	TIER_2_TOKEN: number;
	TIER_3: number;
	TIER_3_TOKEN: number;
};

export type TSubscriptionPrices = {
	subscriptionPrices: TSubscriptionPricesInner;
};

export type TSubscriptionFormData = {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
	numberOfLicenses: number;
} & TCreateSubscriptionPayload;

export const createSubscription = async (
	payload: TCreateSubscriptionPayload,
): Promise<TSubscriptionResponse> => {
	const sessionId = localStorage.getItem(sessionIdLSKey) ?? crypto.randomUUID();

	if (!localStorage.getItem(sessionIdLSKey)) {
		localStorage.setItem(sessionIdLSKey, sessionId);
	}

	const { data } = await axios.post<TSubscriptionResponse>(
		`${API_PREFIX}/${CONTROLLER_PREFIX}/${sessionId}`,
		payload,
	);
	return data;
};

export const changeNumberOfLicenses = async (numberOfLicenses: number): Promise<any> => {
	const sessionId = localStorage.getItem(sessionIdLSKey);

	if (!sessionId) {
		throw new Error('There is no session id provided');
	}

	const { data } = await axios.patch<any>(
		`${API_PREFIX}/${CONTROLLER_PREFIX}/${sessionId}/number-of-licenses`,
		{ numberOfLicenses },
	);
	return data;
};

export const contactSales = async (payload: TContactSalesPayload): Promise<void> => {
	await axios.post<void>(`${API_PREFIX}/${CONTROLLER_PREFIX}/enterprise`, payload);
};

export const getPrices = async (): Promise<TSubscriptionPrices> => {
	const { data } = await axios.get<TSubscriptionPrices>(
		`${API_PREFIX}/${CONTROLLER_PREFIX}/prices`,
	);

	return data;
};

export const validateDomainString = async (params = {}): Promise<void> => {
	await axios.get<void>(`${API_PREFIX}/${CONTROLLER_PREFIX}/validate-domain`, { params });
};

export const deleteCoupon = async (): Promise<TSubscriptionResponse> => {
	const sessionId = localStorage.getItem(sessionIdLSKey);

	if (!sessionId) {
		throw new Error('There is no session id provided');
	}

	const { data } = await axios.delete<TSubscriptionResponse>(
		`${API_PREFIX}/${CONTROLLER_PREFIX}/${sessionId}/coupons`,
	);

	return data;
};

export const applySubscriptionCoupon = async (couponId: string): Promise<TSubscriptionResponse> => {
	const sessionId = localStorage.getItem(sessionIdLSKey);

	if (!sessionId) {
		throw new Error('There is no session id provided');
	}

	const { data } = await axios.post<TSubscriptionResponse>(
		`${API_PREFIX}/${CONTROLLER_PREFIX}/${sessionId}/coupons/${couponId}/apply`,
	);
	return data;
};

export const assignCustomer = async (payload: TAssignCustomerPayload): Promise<void> => {
	const sessionId = localStorage.getItem(sessionIdLSKey);

	if (!sessionId) {
		throw new Error('There is no session id provided');
	}

	await axios.put<void>(
		`${API_PREFIX}/${CONTROLLER_PREFIX}/${sessionId}/customer-assigning`,
		payload,
		{ timeout: 300000 },
	);
};

export const finalizeSubscription = async (): Promise<void> => {
	const sessionId = localStorage.getItem(sessionIdLSKey);

	if (!sessionId) {
		throw new Error('There is no session id provided');
	}

	await axios.post<void>(`${API_PREFIX}/${CONTROLLER_PREFIX}/${sessionId}/finalize`);
};

export const validateEmail = async (params: { email: string }): Promise<{ isValid: boolean }> => {
	const { data } = await axios.get<{ isValid: boolean }>(
		`${API_PREFIX}/${CONTROLLER_PREFIX}/validate-email`,
		{ params, timeout: 300000 },
	);

	return data;
};
