import { FC, PropsWithChildren } from 'react';

import { Flex } from 'antd';

import styles from './FormCardContainer.module.scss';

type TFormCardContainerProps = PropsWithChildren & {
	title: string;
};

const FormCardContainer: FC<TFormCardContainerProps> = ({ title, children }): JSX.Element => {
	return (
		<Flex vertical className={styles.container}>
			<div className={styles.header}>
				<p className={styles.title}>{title}</p>
			</div>
			<div className={styles.content}>{children}</div>
		</Flex>
	);
};

export default FormCardContainer;
