import { FC } from 'react';

import { Flex, Form } from 'antd';
import { TSubscriptionPricesInner } from 'shared/api/subscription.service';

import Tier from '../Tier';

type TTiersContainer = {
	prices: TSubscriptionPricesInner;
};

const TiersContainer: FC<TTiersContainer> = ({ prices }): JSX.Element => {
	const subscriptionPlanTypesLength = Form.useWatch('subscriptionPlanTypes')?.length || 0;

	return (
		<Flex style={{ width: '100%' }} gap={10} justify="center">
			{subscriptionPlanTypesLength === 0 && <Tier price={0} title="Tier" tokenPrice={0} />}
			{subscriptionPlanTypesLength === 1 && (
				<Tier price={prices?.TIER_1} title="Tier 1" tokenPrice={prices?.TIER_1_TOKEN} />
			)}
			{subscriptionPlanTypesLength === 2 && (
				<Tier price={prices?.TIER_2} title="Tier 2" tokenPrice={prices?.TIER_2_TOKEN} />
			)}
			{subscriptionPlanTypesLength === 3 && (
				<Tier price={prices?.TIER_3} title="Tier 3" tokenPrice={prices?.TIER_3_TOKEN} />
			)}
		</Flex>
	);
};

export default TiersContainer;
