import { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';

import { useElements, useStripe } from '@stripe/react-stripe-js';
import { Form, message } from 'antd';
import { pick } from 'lodash';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
	TAssignCustomerPayload,
	TCreateSubscriptionPayload,
	TSubscriptionFormData,
	TSubscriptionResponse,
	assignCustomer,
	createSubscription,
	finalizeSubscription,
	validateEmail,
} from 'shared/api/subscription.service';
import { lsUserEmailKey } from 'shared/constants/constants';

type TFormWrapperProps = {
	setFormStepsData: Dispatch<SetStateAction<TSubscriptionFormData>>;
	setSubscriptionResponse: Dispatch<SetStateAction<TSubscriptionResponse>>;
	setIsProcessing: Dispatch<SetStateAction<boolean>>;
	setFormStep: Dispatch<SetStateAction<number>>;
	formStep: number;
	formStepsData: TSubscriptionFormData;
} & PropsWithChildren;

const FormWrapper: FC<TFormWrapperProps> = ({
	children,
	setFormStepsData,
	setSubscriptionResponse,
	setIsProcessing,
	setFormStep,
	formStep,
	formStepsData,
}): JSX.Element => {
	const [form] = Form.useForm();

	const navigate = useNavigate();

	const stripe = useStripe();
	const elements = useElements();

	const { mutateAsync: createSubscriptionAsync } = useMutation({
		mutationFn: (formValues: TCreateSubscriptionPayload) => createSubscription(formValues),
	});

	const { mutateAsync: assignCustomerAsync } = useMutation({
		mutationFn: (formValues: TAssignCustomerPayload) => assignCustomer(formValues),
	});

	const { mutateAsync: finalizeSubscriptionAsync } = useMutation({
		mutationFn: () => finalizeSubscription(),
	});

	const { mutateAsync: validateEmailAsync } = useMutation({
		mutationFn: (params: { email: string }) => validateEmail(params),
	});

	const onFinish = async (formValues: TSubscriptionFormData): Promise<void> => {
		try {
			if (formStep === 0) {
				setIsProcessing(true);
				setFormStepsData((prev) => ({ ...prev, ...formValues }));
				const response = await createSubscriptionAsync(
					pick(formValues, ['subscriptionPlanTypes', 'subscriptionAddonTypes']),
				);
				setSubscriptionResponse(response);
				setIsProcessing(false);
				setFormStep(1);
			}

			if (formStep === 1) {
				setFormStepsData((prev) => ({ ...prev, ...formValues }));
				localStorage.setItem(lsUserEmailKey, formValues.email);

				setIsProcessing(true);

				const assignCustomerPayload = pick(formValues, [
					'firstName',
					'lastName',
					'email',
					'password',
				]);
				const response = await validateEmailAsync({ email: formValues.email });

				if (response.isValid) {
					await assignCustomerAsync(assignCustomerPayload);

					const setupIntent = await stripe.confirmSetup({
						elements,
						confirmParams: {
							return_url: `${window.location.origin}/success`,
						},
						redirect: 'if_required',
					});

					if (setupIntent.error) {
						message.error(setupIntent.error.message);
					} else if (setupIntent?.setupIntent.status === 'succeeded') {
						try {
							await finalizeSubscriptionAsync();
							navigate(`/success`);
						} catch (e) {
							console.log(e);
						}

						message.success('Setup card succeeded');
					} else {
						message.error('Unexpected state');
					}
				} else {
					form.setFields([
						{
							name: 'email',
							errors: ['Email already exists'],
						},
					]);
				}

				setIsProcessing(false);
			}
		} catch (e) {
			setIsProcessing(false);
			message.error(e?.response?.data?.message || e.message || 'Error!');
		}
	};

	return (
		<Form
			requiredMark={false}
			id="registration-form"
			form={form}
			layout="vertical"
			onFinish={onFinish}
			validateTrigger="onChange"
			initialValues={{ numberOfLicenses: 1, ...formStepsData }}
		>
			{children}
		</Form>
	);
};

export default FormWrapper;
