import { FC, ReactNode } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Flex, Spin } from 'antd';

import styles from './WithLoader.module.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

type TWithLoaderProps = {
	isLoading: boolean;
	children: ReactNode;
};

const WithLoader: FC<TWithLoaderProps> = ({ isLoading = false, children }): JSX.Element => {
	return isLoading ? (
		<div className={styles.container}>
			<Spin indicator={antIcon} />
		</div>
	) : (
		<>{children}</>
	);
};

export default WithLoader;
