import { Dispatch, FC, SetStateAction } from 'react';

import { Button, Flex, Form } from 'antd';
import { TSubscriptionFormData } from 'shared/api/subscription.service';

type TFormActionsProps = {
	setFormStep: Dispatch<SetStateAction<number>>;
	isProcessing: boolean;
	setFormStepsData: Dispatch<SetStateAction<TSubscriptionFormData>>;
};

const FormActions: FC<TFormActionsProps> = ({
	setFormStep,
	isProcessing,
	setFormStepsData,
}): JSX.Element => {
	const form = Form.useFormInstance();
	const handleBack = (): void => {
		const values = form.getFieldsValue();
		setFormStepsData((prev) => ({ ...prev, ...values }));
		setFormStep(0);
	};

	return (
		<Flex style={{ width: '100%', marginTop: 24 }} gap={16}>
			<Button style={{ width: '100%' }} disabled={isProcessing} onClick={handleBack}>
				Back
			</Button>
			<Button
				style={{ width: '100%' }}
				loading={isProcessing}
				type="primary"
				htmlType="submit"
				form="registration-form"
			>
				Start Trial
			</Button>
		</Flex>
	);
};

export default FormActions;
