import { Dispatch, FC, SetStateAction } from 'react';

import { Form, Input, message } from 'antd';
import { useMutation } from 'react-query';
import { TContactSalesPayload, contactSales } from 'shared/api/subscription.service';
import { validatePhoneNumber } from 'shared/lib/lib';
import RequiredFormLabel from 'shared/ui/RequiredFormLabel';

type TContactSalesModalFormProps = {
	setStep: Dispatch<SetStateAction<number>>;
	formId: string;
};
const ContactSalesModalForm: FC<TContactSalesModalFormProps> = ({
	setStep,
	formId,
}): JSX.Element => {
	const [form] = Form.useForm();

	const { mutateAsync: contactSalesAsync } = useMutation({
		mutationFn: (payload: TContactSalesPayload) => contactSales(payload),
		mutationKey: ['contact-sales'],
	});

	const onFinish = async (formValues: TContactSalesPayload): Promise<void> => {
		const payload = {
			...formValues,
			...(formValues?.contactPhone && { contactPhone: `+1${formValues.contactPhone}` }),
		};

		try {
			await contactSalesAsync(payload);
			setStep(1);
		} catch (e) {
			message.error(e?.response?.data?.message || e.message || 'Error!');
		}
	};

	return (
		<Form
			requiredMark={false}
			id={formId}
			form={form}
			layout="vertical"
			onFinish={onFinish}
			validateTrigger="onChange"
		>
			<Form.Item
				label={<RequiredFormLabel label="First Name" />}
				name="firstName"
				rules={[{ required: true, message: 'Required field', whitespace: true }]}
			>
				<Input placeholder="Enter First Name" />
			</Form.Item>
			<Form.Item
				label={<RequiredFormLabel label="Last Name" />}
				name="lastName"
				rules={[{ required: true, message: 'Required field', whitespace: true }]}
			>
				<Input placeholder="Enter Last Name" />
			</Form.Item>

			<Form.Item
				label={<RequiredFormLabel label="Email" />}
				name="email"
				rules={[{ required: true, message: 'Required field', whitespace: true }]}
			>
				<Input type="email" placeholder="Enter Email" />
			</Form.Item>
			<Form.Item
				name="contactPhone"
				label="Phone Number"
				rules={[
					{
						required: false,
						message: 'Please enter your phone number.',
					},
					{
						validator: validatePhoneNumber,
					},
				]}
			>
				<Input
					type="tel"
					style={{ width: '100%' }}
					addonBefore="+1"
					placeholder="Enter Phone Number"
				/>
			</Form.Item>
			<Form.Item label="Company Name" name="companyName">
				<Input placeholder="Enter Company Name" />
			</Form.Item>
		</Form>
	);
};

export default ContactSalesModalForm;
