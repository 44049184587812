import { FC } from 'react';

import { Flex } from 'antd';
import AppHeader from 'pages/AppHeader';
import { Outlet } from 'react-router-dom';
import useGetMicrosoftAuthCode from 'shared/hooks/useGetMicrosoftAuthCode';

import styles from './Layout.module.scss';

const Layout: FC = (): JSX.Element => {
	useGetMicrosoftAuthCode();

	return (
		<Flex vertical gap={36} className={styles.container}>
			<AppHeader />
			<Outlet />
		</Flex>
	);
};

export default Layout;
