import { Dispatch, FC, SetStateAction, useEffect } from 'react';

import { Button, Form, message } from 'antd';
import { ReactComponent as MicrosoftIcon } from 'assets/svg/microsoft.svg';
import { useMutation } from 'react-query';
import { createMicrosoftConnection } from 'shared/api/external-auth.service';
import { TSubscriptionFormData } from 'shared/api/subscription.service';

import { handleMicrosoftLogin } from './lib';

export type TMicrosoftAuthButtonProps = {
	setFormStepsData: Dispatch<SetStateAction<TSubscriptionFormData>>;
};

const MicrosoftAuthButton: FC<TMicrosoftAuthButtonProps> = ({ setFormStepsData }): JSX.Element => {
	const form = Form.useFormInstance();

	const { mutateAsync: createMicrosoftConnectionAsync } = useMutation({
		mutationFn: (code: string) => createMicrosoftConnection(code),
	});

	const createMicrosoftConn = async (cade: string): Promise<void> => {
		try {
			const response = await createMicrosoftConnectionAsync(cade);
			form.setFieldsValue(response);

			setFormStepsData((prev) => ({ ...prev, ...response }));
		} catch (e) {
			message.error(e?.response?.data?.message || e.message || 'Error!');
		} finally {
			localStorage.removeItem('code');
		}
	};

	useEffect(() => {
		const handleStorageChange = (event: StorageEvent): void => {
			if (event.key === 'code') {
				const authCode = event.newValue;
				if (authCode) {
					createMicrosoftConn(authCode);
				}
			}
		};

		window.addEventListener('storage', handleStorageChange);

		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);

	return (
		<Button
			onClick={handleMicrosoftLogin}
			style={{ width: '100%', minWidth: 235 }}
			icon={<MicrosoftIcon />}
		>
			Sign up with Microsoft
		</Button>
	);
};

export default MicrosoftAuthButton;
