import { FC, useEffect } from 'react';

import { lsUserEmailKey, sessionIdLSKey } from 'shared/constants/constants';
import Success from 'widgets/Success';

const SuccessPage: FC = (): JSX.Element => {
	useEffect(() => {
		localStorage.removeItem(sessionIdLSKey);
		const email = localStorage.getItem(lsUserEmailKey);

		if (email) {
			const asyncScript = document.createElement('script');
			asyncScript.src = '//cdn.trackdesk.com/tracking.js';
			asyncScript.async = true;
			document.body.appendChild(asyncScript);

			const inlineScript = document.createElement('script');
			inlineScript.innerHTML = `
			  (function(t,d,k){
				(t[k]=t[k]||[]).push(d);
				t[d]=t[d]||t[k].f||function(){
				  (t[d].q=t[d].q||[]).push(arguments)
				}
			  })(window, "trackdesk", "TrackdeskObject");

			  trackdesk('corvus-link', 'externalCid', {
				externalCid: ${JSON.stringify(email)},
				revenueOriginId: '3c06d223-f48c-454f-8175-f45b7e4ba4fa'
			  });
			`;
			document.body.appendChild(inlineScript);

			localStorage.removeItem(lsUserEmailKey);
			return () => {
				document.body.removeChild(asyncScript);
				if (inlineScript.parentNode) {
					document.body.removeChild(inlineScript);
				}
			};
		}
	}, []);

	return (
		<>
			<Success />
		</>
	);
};

export default SuccessPage;
