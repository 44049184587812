import { FC } from 'react';

import { GoogleOAuthProvider } from '@react-oauth/google';
import Layout from 'Layout';
import RegistrationPlanFormPage from 'pages/RegistrationPlanFormPage';
import SuccessPage from 'pages/SuccessPage';
import ReactGA from 'react-ga4';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import styles from './App.module.scss';

const App: FC = (): JSX.Element => {
	ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

	return (
		<main className={styles.appContainer}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Layout />}>
						<Route
							index
							element={
								<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
									<RegistrationPlanFormPage />
								</GoogleOAuthProvider>
							}
						/>
						<Route path="success" element={<SuccessPage />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</main>
	);
};

export default App;
