import { FC } from 'react';

import { ReactComponent as MainLogoIcon } from 'assets/svg/corvusLogoMain.svg';
import { corvusRedirectLink } from 'shared/constants/constants';

import styles from './AppHeader.module.scss';

const AppHeader: FC = (): JSX.Element => {
	return (
		<header className={styles.logoContainer}>
			<a style={{ width: '100%' }} href={corvusRedirectLink}>
				<MainLogoIcon />
			</a>
		</header>
	);
};

export default AppHeader;
