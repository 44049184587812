import { FC, ReactElement } from 'react';

import { ReactComponent as RequiredIcon } from 'assets/svg/required-star.svg';

import styles from './RequiredFormLabel.module.scss';

type TRequiredFormLabelProps = {
	label?: string;
	children?: ReactElement;
	style?: React.CSSProperties;
};

const RequiredFormLabel: FC<TRequiredFormLabelProps> = ({
	label,
	children,
	style,
}): JSX.Element => {
	return (
		<div className={styles.container} style={style}>
			{label && label}
			{children && children}
			<RequiredIcon className={styles.icon} />
		</div>
	);
};

export default RequiredFormLabel;
