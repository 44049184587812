import { FC, useState } from 'react';

import { Button, ButtonProps } from 'antd';
import { TSubscriptionPricesInner } from 'shared/api/subscription.service';

import AddOnsModal from '../AddOnsModal/AddOnsModal';

type TAddOnsModalWithButtonProps = {
	isLoading: boolean;
	prices: TSubscriptionPricesInner;
} & ButtonProps;

const AddOnsModalWithButton: FC<TAddOnsModalWithButtonProps> = (props): JSX.Element => {
	const { isLoading, prices, ...restProps } = props;

	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<Button {...restProps} onClick={(): void => setShowModal(true)}>
				Continue
			</Button>
			<AddOnsModal
				isLoading={isLoading}
				showModal={showModal}
				setShowModal={setShowModal}
				prices={prices}
			/>
		</>
	);
};

export default AddOnsModalWithButton;
