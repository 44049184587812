import { FC, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Flex } from 'antd';
import MicrosoftAuthButton from 'fetures/MicrosoftAuthButton';
import {
	RegistrationPlanFormStepOne,
	RegistrationPlanFormStepTwo,
} from 'fetures/RegistrationPlanFormSteps';
import { useQuery } from 'react-query';
import {
	TSubscriptionFormData,
	TSubscriptionResponse,
	getPrices,
} from 'shared/api/subscription.service';
import { stripeStyles } from 'shared/configs/stripeTheme/stripeTheme';
import WithLoader from 'shared/ui/WithLoader';

import styles from './RegistrationPlanForm.module.scss';
import FormWrapper from './ui/FormWrapper';

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const RegistrationPlanForm: FC = (): JSX.Element => {
	const [formStep, setFormStep] = useState(0);

	const [formStepsData, setFormStepsData] = useState<TSubscriptionFormData | null>(null);
	const [subscriptionResponse, setSubscriptionResponse] = useState<TSubscriptionResponse | null>(
		null,
	);
	const [isProcessing, setIsProcessing] = useState(false);

	const { data: prices, isLoading: isLoadingPrices } = useQuery({
		queryFn: () => getPrices(),
		queryKey: ['prices'],
		select: (data) => data.subscriptionPrices,
	});

	return (
		<WithLoader isLoading={isLoadingPrices}>
			<Flex justify="center" gap={48} className={styles.container} id="form-container">
				{formStep === 0 && (
					<Elements
						stripe={stripePromise}
						options={{
							clientSecret: subscriptionResponse?.paymentToken,
							appearance: stripeStyles,
						}}
					>
						<FormWrapper
							setFormStepsData={setFormStepsData}
							setSubscriptionResponse={setSubscriptionResponse}
							setIsProcessing={setIsProcessing}
							setFormStep={setFormStep}
							formStep={formStep}
							formStepsData={formStepsData}
						>
							<RegistrationPlanFormStepOne isLoading={isProcessing} prices={prices} />
						</FormWrapper>
					</Elements>
				)}
				{formStep === 1 && subscriptionResponse && (
					<Elements
						stripe={stripePromise}
						options={{
							clientSecret: subscriptionResponse.paymentToken,
							appearance: stripeStyles,
						}}
					>
						<FormWrapper
							setFormStepsData={setFormStepsData}
							setSubscriptionResponse={setSubscriptionResponse}
							setIsProcessing={setIsProcessing}
							setFormStep={setFormStep}
							formStep={formStep}
							formStepsData={formStepsData}
						>
							<RegistrationPlanFormStepTwo
								subscriptionResponse={subscriptionResponse}
								setSubscriptionResponse={setSubscriptionResponse}
								setFormStep={setFormStep}
								isProcessing={isProcessing}
								setFormStepsData={setFormStepsData}
							>
								{/* <GoogleAuthButton setFormStepsData={setFormStepsData} /> */}
								<MicrosoftAuthButton setFormStepsData={setFormStepsData} />
							</RegistrationPlanFormStepTwo>
						</FormWrapper>
					</Elements>
				)}
			</Flex>
		</WithLoader>
	);
};

export default RegistrationPlanForm;
