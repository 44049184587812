import { TEAR_PRODUCT_TYPE } from 'shared/api/subscription.service';

export const corvusRedirectLink = 'https://www.corvuslink.com';
export const cloudCorvusRedirectLink = 'https://cloud.corvuslink.com/login';
export const existsEmailValidateStatus = 'DOMAIN_IS_ALREADY_TAKEN';
export const sessionIdLSKey = 'sessionId';

export const tiersTransform = {
	[TEAR_PRODUCT_TYPE.TIER_1]: 'Tier 1',
	[TEAR_PRODUCT_TYPE.TIER_2]: 'Tier 2',
	[TEAR_PRODUCT_TYPE.TIER_3]: 'Tier 3',
};
export const lsUserEmailKey = 'userEmail';
