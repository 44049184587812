import axios, { API_PREFIX } from 'shared/configs/axios/axios';

const CONTROLLER_PREFIX = 'external-auth';

type TAuthResponse = {
	firstName: string;
	lastName: string;
	email: string;
};

export const createGoogleConnection = async (authorizationCode: string): Promise<TAuthResponse> => {
	const { data } = await axios.post<TAuthResponse>(`${API_PREFIX}/${CONTROLLER_PREFIX}/google`, {
		authorizationCode,
	});
	return data;
};

export const createMicrosoftConnection = async (
	authorizationCode: string,
): Promise<TAuthResponse> => {
	const { data } = await axios.post<TAuthResponse>(`${API_PREFIX}/${CONTROLLER_PREFIX}/microsoft`, {
		authorizationCode,
	});
	return data;
};
